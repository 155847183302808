
import { ShareLinkOptionsRequired, Version } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../../helpers/analytics'

const shareLinkOptions: ShareLinkOptionsRequired = {
  connectionIds: null,
  diagramId: null,
  domainId: null,
  drawer: null,
  expandedIds: null,
  filterIds: null,
  filterIdsExcluded: null,
  filterIdsIncluded: null,
  flowId: null,
  flowParentIds: null,
  flowPathIds: null,
  flowStepId: null,
  mode: 'diagrams',
  modelId: null,
  objectIds: null,
  objectIdsFocused: null,
  objectTab: null,
  overlayGroupId: null,
  overlayIdsFocused: null,
  overlayIdsHidden: null,
  overlayIdsPinned: null,
  overlayTab: null,
  preventNavigation: false,
  search: null,
  x1: null,
  x2: null,
  y: null,
  y1: null,
  y2: null
}

@Component({
  name: 'VersionMenuListItem'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  @Prop() readonly version!: Version

  expanded = false
  loadingShareLink = false
  loadingShareLinkExpanded = false

  shareLinkCopied = false
  shareLinkCopyTimer?: number

  shareLinkExpandedCopied = false
  shareLinkExpandedCopyTimer?: number

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get versionLatest () {
    return this.version.tags.includes('latest')
  }

  get versionShareLink () {
    return this.version.tags.includes('share-link')
  }

  get versionActive () {
    return this.versionLatest && (this.version.id === this.currentVersionId || this.$routeName === 'editor-diagram')
  }

  get shareLink () {
    return this.shareModule.shareLinks.find(o => o.versionId === this.version.id)
  }

  get shareLinkOptions () {
    return this.shareModule.shareLinkOptions && this.shareLink && this.shareModule.shareLinkOptions.shareLinkId === this.shareLink.id ? this.shareModule.shareLinkOptions : undefined
  }

  get shareLinkUrl () {
    return this.shareLink && this.shareLinkOptions ? `${this.shareModule.shareLinkUrls[this.shareLink.id]}/${this.shareLinkOptions.shortId}` : undefined
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get currentModelObject () {
    const domain = Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
    return Object.values(this.modelModule.objects).find(o => {
      if (this.currentModelHandleId) {
        return o.handleId === this.currentModelHandleId
      } else if (domain) {
        return o.domainId === domain.id && o.type === 'root'
      } else {
        return false
      }
    })
  }

  get currentModelObjectDomain () {
    return this.currentModelObject ? this.domainModule.domains[this.currentModelObject.domainId] : undefined
  }

  get versionModelObject () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.version.modelHandleId)
  }

  get versionModelObjectDomain () {
    return this.versionModelObject ? this.domainModule.domains[this.versionModelObject.domainId] : undefined
  }

  get versionModelParent () {
    return !this.versionLatest && this.versionModelObject?.handleId !== this.currentModelObject?.handleId && this.currentOrganizationLimits.versionObjects
  }

  get versionModelName () {
    if (this.versionModelObject && this.versionModelObject.type === 'root' && this.currentModelObjectDomain && this.currentOrganizationLimits.versionObjects && this.currentOrganizationLimits.domains > 1) {
      return this.currentModelObjectDomain.name
    } else if (this.versionModelObject && this.versionModelObject.type !== 'root' && this.currentOrganizationLimits.versionObjects) {
      return this.versionModelObject.name || `${this.versionModelObject.type.slice(0, 1).toUpperCase()}${this.versionModelObject.type.slice(1)}`
    } else {
      return this.currentLandscape.name
    }
  }

  get creatorName () {
    if (this.userModule.user?.id === this.version.createdById) {
      return 'You'
    } else {
      return this.organizationModule.organizationUsers[this.currentLandscape.organizationId]?.[this.version.createdById]?.name || ''
    }
  }

  get latestLink (): Location {
    return {
      name: this.$routeName && this.$routeName !== 'version-diagram' ? this.$routeName : 'editor-diagram',
      params: {
        landscapeId: this.$params.landscapeId,
        versionId: 'latest'
      },
      query: this.$setQuery({
        timeline_x: undefined,
        version_menu: undefined
      })
    }
  }

  get link (): Location {
    return {
      name: this.$routeName && this.$routeName !== 'editor-diagram' ? this.$routeName : 'version-diagram',
      params: {
        landscapeId: this.$params.landscapeId,
        versionId: this.version.id
      },
      query: this.$setQuery({
        diagram: this.currentDiagramHandleId && this.version.diagramHandleIds.includes(this.currentDiagramHandleId) ? this.currentDiagramHandleId : undefined,
        version_menu: undefined
      })
    }
  }

  destroyed () {
    clearTimeout(this.shareLinkCopyTimer)
    this.shareLinkCopyTimer = undefined
  }

  async expandVersion () {
    if (!this.expanded) {
      analytics.versionListExpandItem.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        versionCreatedAt: this.version.createdAt,
        versionModelId: this.versionModelObject?.id || null,
        versionModelType: this.versionModelObject?.type || null,
        versionName: this.version.name,
        versionNotesLength: this.version.notes.length
      })
    }

    this.expanded = !this.expanded

    if (this.versionShareLink && !this.shareLinkUrl) {
      try {
        this.loadingShareLinkExpanded = true
        await this.shareModule.shareLinkFind({
          landscapeId: this.$params.landscapeId,
          versionId: this.version.id
        })
        await this.shareModule.shareLinkOptionsUpsert({
          landscapeId: this.$params.landscapeId,
          props: shareLinkOptions,
          versionId: this.version.id
        })
      } catch (err: any) {
        this.alertModule.pushAlert({
          color: 'error',
          message: err.message
        })
      } finally {
        this.loadingShareLinkExpanded = false
      }
    }
  }

  async loadAndCopyShareLinkUrl () {
    try {
      if (!this.shareLinkUrl) {
        this.loadingShareLink = true
        await this.shareModule.shareLinkFind({
          landscapeId: this.$params.landscapeId,
          versionId: this.version.id
        })
        await this.shareModule.shareLinkOptionsUpsert({
          landscapeId: this.$params.landscapeId,
          props: {
            connectionIds: null,
            diagramId: null,
            domainId: null,
            drawer: null,
            expandedIds: null,
            filterIds: null,
            filterIdsExcluded: null,
            filterIdsIncluded: null,
            flowId: null,
            flowParentIds: null,
            flowPathIds: null,
            flowStepId: null,
            mode: 'diagrams',
            modelId: null,
            objectIds: null,
            objectIdsFocused: null,
            objectTab: null,
            overlayGroupId: null,
            overlayIdsFocused: null,
            overlayIdsHidden: null,
            overlayIdsPinned: null,
            overlayTab: null,
            preventNavigation: false,
            search: null,
            x1: null,
            x2: null,
            y: null,
            y1: null,
            y2: null
          },
          versionId: this.version.id
        })
      }
      if (this.shareLinkUrl && this.shareLink) {
        await navigator.clipboard.writeText(this.shareLinkUrl)

        this.shareLinkCopied = true
        clearTimeout(this.shareLinkCopyTimer)
        this.shareLinkCopyTimer = window.setTimeout(() => {
          this.shareLinkCopied = false
        }, 2000)
      }
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loadingShareLink = false
    }
  }

  async copyShareLinkExpandedUrl () {
    try {
      if (!this.shareLinkUrl) {
        this.loadingShareLinkExpanded = true
        await this.shareModule.shareLinkFind({
          landscapeId: this.$params.landscapeId,
          versionId: this.version.id
        })
        await this.shareModule.shareLinkOptionsUpsert({
          landscapeId: this.$params.landscapeId,
          props: {
            connectionIds: null,
            diagramId: null,
            domainId: null,
            drawer: null,
            expandedIds: null,
            filterIds: null,
            filterIdsExcluded: null,
            filterIdsIncluded: null,
            flowId: null,
            flowParentIds: null,
            flowPathIds: null,
            flowStepId: null,
            mode: 'diagrams',
            modelId: null,
            objectIds: null,
            objectIdsFocused: null,
            objectTab: null,
            overlayGroupId: null,
            overlayIdsFocused: null,
            overlayIdsHidden: null,
            overlayIdsPinned: null,
            overlayTab: null,
            preventNavigation: false,
            search: null,
            x1: null,
            x2: null,
            y: null,
            y1: null,
            y2: null
          },
          versionId: this.version.id
        })
      }
      if (this.shareLinkUrl && this.shareLink) {
        await navigator.clipboard.writeText(this.shareLinkUrl)

        this.shareLinkExpandedCopied = true
        clearTimeout(this.shareLinkExpandedCopyTimer)
        this.shareLinkExpandedCopyTimer = window.setTimeout(() => {
          this.shareLinkExpandedCopied = false
        }, 2000)
      }
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loadingShareLinkExpanded = false
    }
  }
}
