
import { Version, VersionsService } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { LandscapeModule } from '@/modules/landscape/store'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../../helpers/analytics'
import { VersionModule } from '../../store'
import RevertDialog from '../dialog/revert-dialog.vue'
import FreezeMenu from './freeze-menu.vue'
import ListItems from './list-menu.vue'

@Component({
  components: {
    FreezeMenu,
    ListItems,
    Menu,
    RevertDialog
  },
  name: 'VersionDropdown'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu
  @Ref() readonly listItemsRef!: any
  @Prop() readonly activator!: (() => HTMLElement | undefined) | undefined

  versions: Version[] | null = null
  freezeLandscapeId: string = ''

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  async open () {
    const authorization = await this.userModule.getAuthorizationHeader()

    const loadedVersions = this.versionModule.versionsListStatus.successInfo.landscapeId === this.currentLandscapeId
    if (loadedVersions) {
      this.versions = [...this.versionModule.versions]
    } else {
      this.versions = (await VersionsService.versionsList(authorization, this.currentLandscapeId)).versions
    }
  }

  opened () {
    analytics.versionListMenu.track(this, {
      landscapeId: [this.currentLandscapeId],
      organizationId: [this.currentOrganizationId]
    })

    this.$nextTick(() => {
      const { $el } = this.listItemsRef as any
      $el.focus()
    })
  }

  close () {
    this.menuRef.close()
  }

  reset () {
    this.versions = null
    this.freezeLandscapeId = ''
    this.listItemsRef?.reset()
  }
}
