
import { FormController, validationRules } from '@icepanel/app-form'
import semver from 'semver'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'

import * as analytics from '../../helpers/analytics'
import { VersionModule } from '../../store'

@Component({
  components: {
    OrganizationUpgradeMenu
  },
  name: 'VersionMenuCreate'
})
export default class extends Vue {
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  formController = new FormController({
    initialModel: {
      name: '',
      notes: ''
    },
    validationRules: {
      name: validationRules.exists,
      notes: [
        ...validationRules.exists,
        ...validationRules.minLength(10)
      ]
    }
  })

  get versionMenu () {
    return this.$queryValue('version_menu')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get modelObjectHandleId () {
    return this.versionMenu?.includes('-') ? this.versionMenu.split('-')[1] : null
  }

  get modelObject () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.modelObjectHandleId)
  }

  get type () {
    return this.modelObject ? this.modelObject.type === 'root' ? 'domain' : this.modelObject.type : 'landscape'
  }

  get previousVersion () {
    return this.versionModule.versions.filter(o => o.modelHandleId === this.modelObjectHandleId && !o.tags.includes('latest'))?.[0]
  }

  get nextVersionName () {
    if (this.previousVersion) {
      const previousName = this.previousVersion.name

      if (semver.valid(previousName)) {
        // 1.1.1
        return semver.inc(previousName, 'patch') || previousName
      } else if (previousName.match(/^\d+$/)) {
        // 1
        return `${parseInt(previousName) + 1}`
      } else if (previousName.match(/^\d+\.\d+$/)) {
        // 1.1
        const [first, second] = previousName.split('.')
        return `${first}.${parseInt(second) + 1}`
      } else if (previousName.match(/^.* \d+$/)) {
        // version 1
        const prefix = previousName.substr(0, previousName.lastIndexOf(' '))
        const ver = parseInt(previousName.substr(previousName.lastIndexOf(' ') + 1))
        return `${prefix} ${ver + 1}`
      } else if (previousName.split(' ').length === 2 && semver.valid(previousName.split(' ')[1])) {
        // version 1.1.1
        const [prefix, version] = previousName.split(' ')
        return `${prefix} ${semver.inc(version, 'patch')}`
      } else if (previousName[0].toLowerCase() === 'v' && semver.valid(previousName.substr(1))) {
        // v1.1.1
        const prefix = previousName[0]
        const ver = semver.inc(previousName.substr(1), 'patch')
        return `${prefix}${ver}`
      } else if (previousName.match(/^(v|V)\d+$/)) {
        // v1
        const prefix = previousName[0]
        const ver = parseInt(previousName.substr(1))
        return `${prefix}${ver + 1}`
      } else {
        return previousName
      }
    } else {
      return '1'
    }
  }

  created () {
    this.formController.setInitialModel({
      name: this.nextVersionName,
      notes: ''
    })

    this.formController.submitHandler = async props => {
      const { id, landscapeId } = await this.versionModule.versionCreate({
        landscapeId: this.$params.landscapeId,
        props: {
          modelHandleId: this.modelObject?.handleId || null,
          name: props.name,
          notes: props.notes
        }
      })

      analytics.versionCreate.track(this, {
        landscapeId: [landscapeId],
        organizationId: [this.currentOrganization.id],
        versionModelId: this.modelObject?.id || null,
        versionModelType: this.modelObject?.type || null,
        versionName: props.name,
        versionNameLength: props.name.length,
        versionNotesLength: props.notes.length
      })

      this.$replaceQuery({
        version_menu: `success-${id}`
      })
    }
  }

  mounted () {
    analytics.versionCreateScreen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }
}
