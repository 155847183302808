import * as analytics from '@icepanel/app-analytics'

import { diagramType } from '@/modules/diagram/helpers/analytics-properties'
import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

import {
  versionCreatedAt,
  versionLatest,
  versionModelId,
  versionModelType,
  versionName,
  versionNameLength,
  versionNotesLength,
  versionRevertNotesLength
} from './analytics-properties'

/**
 * Screens
 */

export const versionDiagramScreen = new analytics.Event({
  displayName: 'Version diagram screen',
  name: 'version_diagram_screen',
  tags: [analytics.Tags.Screen]
}, {
  diagramType,
  landscapeId,
  organizationId,
  versionCreatedAt,
  versionLatest,
  versionModelId,
  versionModelType,
  versionName
})
export const versionCreateScreen = new analytics.Event({
  displayName: 'Version create screen',
  name: 'version_create_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const versionCreateSuccessScreen = new analytics.Event({
  displayName: 'Version create success screen',
  name: 'version_create_success_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})

/**
 * Dialogs
 */

export const versionRevertDialog = new analytics.Event({
  displayName: 'Version revert dialog',
  name: 'version_revert_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})

/**
 * Menu
 */

export const versionListMenu = new analytics.Event({
  displayName: 'Version list menu',
  name: 'version_list_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const versionInfoMenu = new analytics.Event({
  displayName: 'Version info menu',
  name: 'version_info_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const versionNameUpdateMenu = new analytics.Event({
  displayName: 'Version name update menu',
  name: 'version_name_update_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const versionNotesUpdateMenu = new analytics.Event({
  displayName: 'Version notes update menu',
  name: 'version_notes_update_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const versionRevertInfoMenu = new analytics.Event({
  displayName: 'Version revert info menu',
  name: 'version_revert_info_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const versionRevertNotesUpdateMenu = new analytics.Event({
  displayName: 'Version revert notes update menu',
  name: 'version_revert_notes_update_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const versionTimelineDiagramMissingMenu = new analytics.Event({
  displayName: 'Version timeline diagram missing menu',
  name: 'version_timeline_diagram_missing_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})

/**
 * Events
 */

export const versionCreate = new analytics.Event({
  displayName: 'Version create',
  name: 'version_create'
}, {
  landscapeId,
  organizationId,
  versionModelId,
  versionModelType,
  versionName,
  versionNameLength,
  versionNotesLength
})
export const versionListExpandItem = new analytics.Event({
  displayName: 'Version list expand item',
  name: 'version_list_expand_item'
}, {
  landscapeId,
  organizationId,
  versionCreatedAt,
  versionModelId,
  versionModelType,
  versionName,
  versionNotesLength
})
export const versionRevert = new analytics.Event({
  displayName: 'Version revert',
  name: 'version_revert'
}, {
  landscapeId,
  organizationId,
  versionCreatedAt,
  versionName,
  versionNameLength,
  versionNotesLength,
  versionRevertNotesLength
})
export const versionUpdate = new analytics.Event({
  displayName: 'Version update',
  name: 'version_update'
}, {
  landscapeId,
  organizationId,
  versionCreatedAt,
  versionModelId,
  versionName: {
    ...versionName,
    required: false
  },
  versionNameLength: {
    ...versionNameLength,
    required: false
  },
  versionNotesLength: {
    ...versionNotesLength,
    required: false
  }
})
export const versionDrawerExpand = new analytics.Event({
  displayName: 'Version drawer expand',
  name: 'version_drawer_expand'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const versionDrawerCollapse = new analytics.Event({
  displayName: 'Version drawer collapse',
  name: 'version_drawer_collapse'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const versionRevertUpdate = new analytics.Event({
  displayName: 'Version revert update',
  name: 'version_revert_update'
}, {
  landscapeId,
  organizationId,
  versionCreatedAt,
  versionNotesLength: {
    ...versionNotesLength,
    required: false
  }
})

/**
 * Hidden
 */

export const versionModelScreenHidden = new analytics.Event({
  displayName: 'Version model screen',
  hidden: true,
  name: 'version_model_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId,
  versionCreatedAt,
  versionLatest,
  versionModelId,
  versionModelType,
  versionName
})
export const versionTimelineUpgradeMenuHidden = new analytics.Event({
  displayName: 'Version timeline upgrade menu',
  hidden: true,
  name: 'version_timeline_upgrade_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const versionDrawerObjectExpandHidden = new analytics.Event({
  displayName: 'Version drawer object expand',
  hidden: true,
  name: 'version_drawer_object_expand'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const versionDrawerObjectCollapseHidden = new analytics.Event({
  displayName: 'Version drawer object collapse',
  hidden: true,
  name: 'version_drawer_object_collapse'
}, {
  diagramType,
  landscapeId,
  organizationId
})
