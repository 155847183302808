
import semver from 'semver'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'

import * as analytics from '../../helpers/analytics'
import { VersionModule } from '../../store'

@Component({
  name: 'VersionFreezeMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly landscapeId!: string
  @Prop({ default: false }) readonly showDropdown!: boolean

  name = ''
  notes = ''
  errorMessage = ''
  loading = false

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersionId () {
    return this.$params.versionsId || this.currentShareLink?.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get previousVersion () {
    return this.versionModule.versions.filter(o => o.modelHandleId === null && !o.tags.includes('latest'))?.[0]
  }

  get nextVersionName () {
    if (this.previousVersion) {
      const previousName = this.previousVersion.name

      if (semver.valid(previousName)) {
        // 1.1.1
        return semver.inc(previousName, 'patch') || previousName
      } else if (previousName.match(/^\d+$/)) {
        // 1
        return `${parseInt(previousName) + 1}`
      } else if (previousName.match(/^\d+\.\d+$/)) {
        // 1.1
        const [first, second] = previousName.split('.')
        return `${first}.${parseInt(second) + 1}`
      } else if (previousName.match(/^.* \d+$/)) {
        // version 1
        const prefix = previousName.substr(0, previousName.lastIndexOf(' '))
        const ver = parseInt(previousName.substr(previousName.lastIndexOf(' ') + 1))
        return `${prefix} ${ver + 1}`
      } else if (previousName.split(' ').length === 2 && semver.valid(previousName.split(' ')[1])) {
        // version 1.1.1
        const [prefix, version] = previousName.split(' ')
        return `${prefix} ${semver.inc(version, 'patch')}`
      } else if (previousName[0].toLowerCase() === 'v' && semver.valid(previousName.substr(1))) {
        // v1.1.1
        const prefix = previousName[0]
        const ver = semver.inc(previousName.substr(1), 'patch')
        return `${prefix}${ver}`
      } else if (previousName.match(/^(v|V)\d+$/)) {
        // v1
        const prefix = previousName[0]
        const ver = parseInt(previousName.substr(1))
        return `${prefix}${ver + 1}`
      } else {
        return previousName
      }
    } else {
      return '1'
    }
  }

  created () {
    this.name = this.nextVersionName
    this.notes = ''
  }

  mounted () {
    analytics.versionCreateScreen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  async createVersion () {
    this.errorMessage = ''

    if (!this.name.length) {
      this.errorMessage = 'Version name is required'
      return
    }

    if (this.notes.length < 10) {
      this.errorMessage = 'Please give a reason over 10 characters'
      return
    }

    this.loading = true

    const { landscapeId } = await this.versionModule.versionCreate({
      landscapeId: this.$params.landscapeId,
      props: {
        modelHandleId: null,
        name: this.name,
        notes: this.notes
      }
    })

    this.$emit('close')

    analytics.versionCreate.track(this, {
      landscapeId: [landscapeId],
      organizationId: [this.currentOrganization.id],
      versionModelId: null,
      versionModelType: null,
      versionName: this.name,
      versionNameLength: this.name.length,
      versionNotesLength: this.notes.length
    })

    this.alertModule.pushAlert({
      color: 'success',
      message: 'Landscape frozen successfully! You are still editing the latest version.'
    })

    this.loading = false
  }
}
