
import { Version } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { RouteModule } from '@/modules/route/store'
import { VersionModule } from '@/modules/version/store'

import LatestVersionListItem from './latest-version-list-item.vue'
import VersionListItem, { MIN_VERSION_LINE_HEIGHT } from './version-list-item.vue'

@Component({
  components: {
    LatestVersionListItem,
    VersionListItem
  },
  name: 'LandscapeListItems'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly listRef!: { $el: HTMLElement }
  @Ref() readonly listItemRefs!: { $el: HTMLElement }[]

  @Prop({ default: null }) readonly versions!: Version[] | null

  expandedVersionId: string | null = null

  MAX_VERSION_VISIBLE_LINES = 12

  get items (): Version[] {
    return (this.versions ?? [])
      .filter(version => version.modelHandleId === null)
      .filter(version => !version.tags.includes('latest'))
  }

  get loading () {
    return this.versions === null
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId)
  }

  get dropdownHeight () {
    return (this.MAX_VERSION_VISIBLE_LINES * MIN_VERSION_LINE_HEIGHT)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  reset () {
    this.expandedVersionId = null
  }

  toggleExpandedVersion (id: string) {
    this.expandedVersionId = this.expandedVersionId === id ? null : id
  }

  setLandscapeVersion (version: Version) {
    if (this.currentVersionId !== version.id) {
      this.$router.push({
        name: 'landscape',
        params: {
          landscapeId: version.landscapeId,
          versionId: version.tags.includes('latest') ? 'latest' : version.id
        }
      })

      this.routeModule.resetHistory()
    }

    this.$emit('close')
  }

  setLatestVersion () {
    if (!this.currentVersion?.tags.includes('latest')) {
      this.$router.push({
        name: 'landscape',
        params: {
          landscapeId: this.currentLandscapeId,
          versionId: 'latest'
        }
      })

      this.routeModule.resetHistory()
    }

    this.$emit('close')
  }

  goToVersionsDocs () {
    window.open('https://docs.icepanel.io/advanced-features/versioning', '_blank')
  }
}
