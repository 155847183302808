
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'

import { VersionModule } from '../../store'
import Create from './create.vue'
import List from './list.vue'
import Success from './success.vue'

@Component({
  components: {
    Create,
    List,
    Menu,
    Success
  },
  name: 'VersionMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu

  loading = false

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get organizationUpgradeDialog () {
    return this.$queryValue('organization_upgrade_dialog')
  }

  get versionMenu () {
    return this.$queryValue('version_menu')
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions?.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get versions () {
    return this.versionModule.versions
  }

  async open () {
    try {
      if (this.loading || !this.currentLandscape) {
        return
      }

      this.loading = true

      if (this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.successInfo.organizationId !== this.currentOrganizationId) {
        this.organizationModule.organizationUsersList(this.currentOrganizationId)
      }
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loading = false
    }
  }
}
