
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { CommentModule } from '@/modules/comment/store'
import { DiagramModule } from '@/modules/diagram/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'VersionRevertDialog'
})
export default class extends Vue {
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly dialogRef!: Dialog

  get currentOrganizationId () {
    return this.currentLandscape.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get versionRevertDialog () {
    return this.$queryValue('revert_version_dialog')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId)!
  }

  get currentLatestVersion () {
    return this.versionModule.versions.find(o => o.tags.includes('latest'))
  }

  get revertVersion () {
    return this.versionModule.versions.find(o => o.id === this.versionRevertDialog)
  }

  formController = new FormController({
    initialModel: {
      notes: ''
    },
    validationRules: {
      notes: [
        ...validationRules.exists,
        ...validationRules.minLength(10)
      ]
    }
  })

  created () {
    this.formController.submitHandler = async props => {
      if (!this.revertVersion) {
        throw new Error('Could not find revert version')
      }
      if (!this.currentLatestVersion) {
        throw new Error('Could not find latest version')
      }

      await this.$replaceQuery({
        comment: undefined,
        connection: undefined,
        connection_tab: undefined,
        diagram: undefined,
        flow: undefined,
        flow_parent: undefined,
        flow_path: undefined,
        flow_step: undefined,
        model: undefined,
        overlay_group: undefined,
        x1: undefined,
        x2: undefined,
        y1: undefined,
        y2: undefined
      })

      await this.versionModule.versionRevertCreate({
        landscapeId: this.$params.landscapeId,
        props: {
          notes: props.notes,
          versionId: this.revertVersion.id
        }
      })

      analytics.versionRevert.track(this, {
        landscapeId: [this.currentLandscapeId],
        organizationId: [this.currentOrganizationId],
        versionCreatedAt: this.revertVersion.createdAt,
        versionName: this.revertVersion.name,
        versionNameLength: this.revertVersion.name.length,
        versionNotesLength: this.revertVersion.notes.length,
        versionRevertNotesLength: props.notes.length
      })

      this.diagramModule.diagramsUnsubscribe()
      this.flowModule.flowsUnsubscribe()
      this.modelModule.objectsUnsubscribe()
      this.modelModule.connectionsUnsubscribe()
      this.commentModule.commentsUnsubscribe()
      this.tagModule.tagsUnsubscribe()
      this.tagModule.tagGroupsUnsubscribe()

      if (this.currentVersionId === this.currentLatestVersion.id) {
        this.$replaceQuery({
          revert_version_dialog: undefined
        })
      } else {
        this.$router.push({
          params: {
            ...this.$params,
            versionId: this.currentLatestVersion.id
          },
          query: this.$setQuery({
            revert_version_dialog: undefined
          })
        })
      }
    }
  }

  mounted () {
    this.formController.on('success', () => {
      this.dialogRef.close()
    })
  }

  opened () {
    analytics.versionRevertDialog.track(this, {
      landscapeId: [this.currentLandscapeId],
      organizationId: [this.currentOrganizationId]
    })

    this.formController.resetModel()
  }
}
