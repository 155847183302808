import * as farSnowflake from '@fortawesome/free-regular-svg-icons/faSnowflake'
import * as fasCaretDown from '@fortawesome/free-solid-svg-icons/faCaretDown'
import * as fasCaretUp from '@fortawesome/free-solid-svg-icons/faCaretUp'
import * as fasCircle from '@fortawesome/free-solid-svg-icons/faCircle'
import * as fasCopy from '@fortawesome/free-solid-svg-icons/faCopy'
import * as fasExclamationTriangle from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import * as fasEye from '@fortawesome/free-solid-svg-icons/faEye'
import * as fasInfoCircle from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import * as fasPencilAlt from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import * as fasSave from '@fortawesome/free-solid-svg-icons/faSave'
import * as fasTimes from '@fortawesome/free-solid-svg-icons/faTimes'
import * as fasUserCircle from '@fortawesome/free-solid-svg-icons/faUserCircle'

export default [
  farSnowflake.definition,
  fasCaretDown.definition,
  fasCaretUp.definition,
  fasCircle.definition,
  fasCopy.definition,
  fasExclamationTriangle.definition,
  fasEye.definition,
  fasInfoCircle.definition,
  fasPencilAlt.definition,
  fasSave.definition,
  fasTimes.definition,
  fasUserCircle.definition
] as const
