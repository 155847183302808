
import { Landscape, PermissionType, Version } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { ShareModule } from '@/modules/share/store'

import * as analytics from '../../helpers/analytics'
import { VersionModule } from '../../store'

export const MIN_VERSION_LINE_HEIGHT = 32
export const MIN_VERSION_EXPANDED_LINE_HEIGHT = 80

@Component({
  name: 'LandscapeVersionListItem'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly active!: boolean
  @Prop() readonly expanded!: boolean
  @Prop() readonly version!: Version
  @Prop() readonly landscapePermission!: PermissionType
  @Prop() readonly currentLandscape!: Landscape

  MIN_VERSION_LINE_HEIGHT = MIN_VERSION_LINE_HEIGHT
  MIN_VERSION_EXPANDED_LINE_HEIGHT = MIN_VERSION_EXPANDED_LINE_HEIGHT

  loadingShareLink = false

  renamingVersion = false
  newVersionName = ''

  changingNotes = false
  newVersionNotes = ''

  get hasPermission () {
    return this.landscapePermission !== 'read'
  }

  get menuItems () {
    const items = [
      {
        action: () => this.renameVersion(),
        disabled: !this.hasPermission,
        icon: '$custom-duotone-pencil',
        id: 'rename',
        name: 'Rename version'
      },
      {
        action: () => this.changeNotes(),
        disabled: !this.hasPermission,
        icon: '$custom-duotone-pen-to-square',
        id: 'edit-notes',
        name: 'Edit notes'
      },
      {
        action: () => this.revertVersion(),
        disabled: !this.hasPermission,
        icon: '$custom-duotone-clock-rotate-left',
        id: 'revert',
        name: 'Revert to version'
      }
    ]

    if (this.hasShareLink) {
      items.push({
        action: () => this.copyShareLinkUrl(),
        disabled: false,
        icon: '$custom-duotone-copy',
        id: 'copy-share-link',
        name: 'Copy share link'
      })
    }

    items.push({
      action: () => this.shareVersion(),
      disabled: !this.hasPermission,
      icon: '$custom-duotone-share',
      id: 'edit-share-link',
      name: this.hasShareLink ? 'Edit share link options' : 'Enable share link'
    })

    return items
  }

  get hasShareLink () {
    return this.version.tags.includes('share-link')
  }

  get shareLink () {
    return this.shareModule.shareLinks.find(o => o.versionId === this.version.id)
  }

  get shareLinkUrl () {
    return this.shareLink && this.shareModule.shareLinkUrls[this.shareLink.id]
  }

  async mounted () {
    await this.loadShareLink()
  }

  async loadShareLink () {
    if (this.hasShareLink && !this.shareLinkUrl) {
      try {
        this.loadingShareLink = true
        await this.shareModule.shareLinkFind({
          landscapeId: this.$params.landscapeId,
          versionId: this.version.id
        })
      } catch (err: any) {
        this.alertModule.pushAlert({
          color: 'error',
          message: err.message
        })
      } finally {
        this.loadingShareLink = false
      }
    }
  }

  async copyShareLinkUrl () {
    this.$emit('close')
    if (this.shareLinkUrl) {
      await navigator.clipboard.writeText(this.shareLinkUrl)
    }
  }

  renameVersion () {
    this.newVersionName = this.version.name
    this.renamingVersion = true
  }

  changeNotes () {
    this.newVersionNotes = this.version.notes
    this.changingNotes = true
  }

  shareVersion () {
    this.$emit('close')
    this.$router.push({
      params: { ...this.$params, versionId: this.version.id },
      query: this.$setQuery({ share_dialog: 'link' })
    })
  }

  revertVersion () {
    this.$emit('close')
    this.$pushQuery({
      revert_version_dialog: this.version.id
    })
  }

  async updateVersionName () {
    if (!this.newVersionName.length) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'Version name is required'
      })
      this.renamingVersion = false
      return
    }

    await this.versionModule.versionUpdate({
      landscapeId: this.$params.landscapeId,
      props: {
        name: this.newVersionName
      },
      versionId: this.version.id
    })

    this.renamingVersion = false
    this.version.name = this.newVersionName

    analytics.versionUpdate.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      versionCreatedAt: this.version.createdAt,
      versionModelId: this.version.modelHandleId,
      versionName: this.newVersionName,
      versionNameLength: this.newVersionName.length
    })
  }

  async updateVersionNotes () {
    if (this.newVersionNotes.length < 10) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'Please give a reason over 10 characters'
      })
      this.changingNotes = false
      return
    }

    await this.versionModule.versionUpdate({
      landscapeId: this.$params.landscapeId,
      props: {
        notes: this.newVersionNotes
      },
      versionId: this.version.id
    })

    this.changingNotes = false
    this.version.notes = this.newVersionNotes

    analytics.versionUpdate.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      versionCreatedAt: this.version.createdAt,
      versionModelId: this.version.modelHandleId,
      versionNotesLength: this.newVersionNotes.length
    })
  }
}
