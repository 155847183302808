
import { PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { MIN_VERSION_LINE_HEIGHT } from '@/modules/version/components/dropdown/version-list-item.vue'

@Component({
  name: 'LandscapeVersionListItem'
})
export default class extends Vue {
  MIN_VERSION_LINE_HEIGHT = MIN_VERSION_LINE_HEIGHT

  @Prop() readonly landscapePermission!: PermissionType

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }
}
