import * as analytics from '@icepanel/app-analytics'
import { ModelObjectType } from '@icepanel/platform-api-client'

export const versionCreatedAt: analytics.Property<Date> = {
  displayName: 'Version created at',
  name: 'version_created_at',
  required: true,
  type: 'date'
}
export const versionName: analytics.Property<string> = {
  displayName: 'Version name',
  name: 'version_name',
  required: true,
  type: 'string'
}
export const versionNameLength: analytics.Property<number> = {
  displayName: 'Version name length',
  name: 'version_name_length',
  required: true,
  type: 'number'
}
export const versionNotesLength: analytics.Property<number> = {
  displayName: 'Version notes length',
  name: 'version_notes_length',
  required: true,
  type: 'number'
}
export const versionRevertNotesLength: analytics.Property<number> = {
  displayName: 'Version revert notes length',
  name: 'version_revert_notes_length',
  required: true,
  type: 'number'
}
export const versionLatest: analytics.Property<boolean> = {
  displayName: 'Version latest',
  name: 'version_latest',
  required: true,
  type: 'boolean'
}
export const versionModelId: analytics.Property<string | null> = {
  displayName: 'Version model ID',
  name: 'version_model_id',
  required: true,
  type: 'string'
}
export const versionModelType: analytics.Property<ModelObjectType | null> = {
  displayName: 'Version model type',
  examples: ['actor', 'app', 'group', 'component', 'root', 'store', 'system'],
  name: 'version_model_type',
  required: true,
  type: 'string'
}
