
import Vue from 'vue'
import Component from 'vue-class-component'
import { Location } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'

import * as analytics from '../../helpers/analytics'
import { VersionModule } from '../../store'
import VersionMenuListItem from './list-item.vue'

@Component({
  components: {
    OrganizationUpgradeMenu,
    VersionMenuListItem
  },
  name: 'VersionMenuList'
})
export default class extends Vue {
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)!
  }

  get currentModelObject () {
    const domain = Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
    return Object.values(this.modelModule.objects).find(o => {
      if (this.currentModelHandleId) {
        return o.handleId === this.currentModelHandleId
      } else if (domain) {
        return o.domainId === domain.id && o.type === 'root'
      } else {
        return false
      }
    })
  }

  get currentModelObjectDomain () {
    return this.currentModelObject ? this.domainModule.domains[this.currentModelObject.domainId] : undefined
  }

  get currentModelObjectFamily () {
    return this.currentModelObject ? [this.currentModelObject.id, ...this.currentModelObject.parentIds].map(o => this.modelModule.objects[o]) : undefined
  }

  get currentModelObjectFamilyHandleIds () {
    return this.currentModelObjectFamily?.map(o => o.handleId)
  }

  get domains () {
    return Object.values(this.domainModule.domains)
  }

  get domainsActive () {
    return this.domains.length >= 2 || !!this.domains[0].name
  }

  get versions () {
    return this.versionModule.versions.filter(o => {
      if (this.currentModelObjectFamilyHandleIds && this.currentOrganizationLimits.versionObjects) {
        return o.modelHandleId === null || this.currentModelObjectFamilyHandleIds.includes(o.modelHandleId)
      } else {
        return o.modelHandleId === null
      }
    })
  }

  get latestVersion () {
    return this.versions.find(o => o.tags.includes('latest'))
  }

  get visibleVersions () {
    return this.versions.slice(0, this.currentOrganizationLimits.versions + 1)
  }

  get hiddenVersions () {
    return this.versions.slice(this.currentOrganizationLimits.versions + 1)
  }

  get title () {
    if (this.currentModelObject && this.currentModelObject.type === 'root' && this.currentModelObjectDomain && this.domainsActive && this.currentOrganizationLimits.versionObjects && this.currentOrganizationLimits.domains > 1) {
      return 'Domain versions'
    } else if (this.currentModelObject && this.currentModelObject.type !== 'root' && this.currentOrganizationLimits.versionObjects) {
      return `${this.currentModelObject.type.slice(0, 1).toUpperCase()}${this.currentModelObject.type.slice(1)} versions`
    } else {
      return 'Landscape versions'
    }
  }

  get subtitle () {
    if (this.currentModelObject && this.currentModelObject.type === 'root' && this.currentModelObjectDomain && this.domainsActive && this.currentOrganizationLimits.versionObjects && this.currentOrganizationLimits.domains > 1) {
      return this.currentModelObjectDomain.name
    } else if (this.currentModelObject && this.currentModelObject.type !== 'root' && this.currentOrganizationLimits.versionObjects) {
      return this.currentModelObject.name
    } else {
      return this.currentLandscape.name
    }
  }

  get latestVersionLink (): Location {
    return {
      name: this.$routeName && this.$routeName !== 'editor-diagram' ? this.$routeName : 'version-diagram',
      params: {
        landscapeId: this.$params.landscapeId,
        versionId: this.latestVersion?.id || ''
      },
      query: this.$setQuery({
        timeline_x: undefined,
        version_menu: undefined
      })
    }
  }

  mounted () {
    analytics.versionListMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }
}
