
import Vue from 'vue'
import Component from 'vue-class-component'
import { Location } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'

import * as analytics from '../../helpers/analytics'
import { VersionModule } from '../../store'

@Component({
  name: 'VersionMenuSuccess'
})
export default class extends Vue {
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get versionMenu () {
    return this.$queryValue('version_menu')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get versionId () {
    return this.versionMenu?.includes('-') ? this.versionMenu?.split('-')[1] : null
  }

  get version () {
    return this.versionModule.versions.find(o => o.id === this.versionId)!
  }

  get versionModelObject () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.version.modelHandleId)
  }

  get type () {
    return this.versionModelObject ? this.versionModelObject.type === 'root' ? 'domain' : this.versionModelObject.type : 'landscape'
  }

  get link (): Location {
    return {
      name: this.$routeName && this.$routeName !== 'editor-diagram' ? this.$routeName : 'version-diagram',
      params: {
        landscapeId: this.$params.landscapeId,
        versionId: this.version.id
      },
      query: this.$setQuery({
        diagram: this.currentDiagramHandleId && this.version.diagramHandleIds.includes(this.currentDiagramHandleId) ? this.currentDiagramHandleId : undefined,
        version_menu: undefined
      })
    }
  }

  mounted () {
    analytics.versionCreateSuccessScreen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }
}
